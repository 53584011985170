const minutesList = [
  { quantity: 5, label: "5 min." },
  { quantity: 10, label: "10 min." },
  { quantity: 15, label: "15 min." },
  { quantity: 20, label: "20 min." },
  { quantity: 25, label: "25 min." },
  { quantity: 30, label: "30 min." },
  { quantity: 35, label: "35 min." },
  { quantity: 40, label: "40 min." },
  { quantity: 45, label: "45 min." },
  { quantity: 50, label: "50 min." },
  { quantity: 55, label: "55 min." },
  { quantity: 60, label: "60 min." },
];

const agendaFields = [
  { class: "text-center", key: "name", label: "Nombre" },
  { class: "text-center", key: "agenda_users", label: "Permisos de Usuario" },
  { class: "text-center", key: "preview", label: "Preview" },
  { class: "text-center", key: "actions", label: "" },
];

const agendaTypes = [
  { name: "SupAdmin", value: "superadmin" },
  { name: "Admin", value: "admin" },
  { name: "Manager", value: "manager" },
  { name: "ABC", value: "abc" },
  { name: "Manager ABC", value: "manager_abc" },
  { name: "Control", value: "control" },
  { name: "Recepcion", value: "reception" },
  { name: "Caja", value: "cash_register" },
  { name: "Externos", value: "external" },
  { name: "Quirofano", value: "operating_room" },
  { name: "Medico", value: "medic" },
  { name: "Enfermería", value: "nursing" },
  { name: "Nutricion", value: "nutrition" },
  { name: "Psicologo", value: "psychology" },
  { name: "Laboratorio", value: "laboratory" },
  { name: "ManagerCacit", value: "manager_cacit" },
  { name: "Cacit", value: "cacit" },
];

const hoursListSettings = [
  { value: "01:00", text: "01:00" },
  { value: "01:30", text: "01:30" },
  { value: "02:00", text: "02:00" },
  { value: "02:30", text: "02:30" },
  { value: "03:00", text: "03:00" },
  { value: "03:30", text: "03:30" },
  { value: "04:00", text: "04:00" },
  { value: "04:30", text: "04:30" },
  { value: "05:00", text: "05:00" },
  { value: "05:30", text: "05:30" },
  { value: "06:00", text: "06:00" },
  { value: "06:30", text: "06:30" },
  { value: "07:00", text: "07:00" },
  { value: "07:30", text: "07:30" },
  { value: "08:00", text: "08:00" },
  { value: "08:30", text: "08:30" },
  { value: "09:00", text: "09:00" },
  { value: "09:30", text: "09:30" },
  { value: "10:00", text: "10:00" },
  { value: "10:30", text: "10:30" },
  { value: "11:00", text: "11:00" },
  { value: "11:30", text: "11:30" },
  { value: "12:00", text: "12:00" },
  { value: "12:30", text: "12:30" },
  { value: "13:00", text: "13:00" },
  { value: "13:30", text: "13:30" },
  { value: "14:00", text: "14:00" },
  { value: "14:30", text: "14:30" },
  { value: "15:00", text: "15:00" },
  { value: "15:30", text: "15:30" },
  { value: "16:00", text: "16:00" },
  { value: "16:30", text: "16:30" },
  { value: "17:00", text: "17:00" },
  { value: "17:30", text: "17:30" },
  { value: "18:00", text: "18:00" },
  { value: "18:30", text: "18:30" },
  { value: "19:00", text: "19:00" },
  { value: "19:30", text: "19:30" },
  { value: "20:00", text: "20:00" },
  { value: "20:30", text: "20:30" },
  { value: "21:00", text: "21:00" },
  { value: "21:30", text: "21:30" },
  { value: "22:00", text: "22:00" },
  { value: "22:30", text: "22:30" },
  { value: "23:00", text: "23:00" },
  { value: "23:30", text: "23:30" },
  { value: "24:00", text: "24:00" },
];

const hoursList = [
  { value: null, text: "N/A" },
  { value: "01:00", text: "01:00" },
  { value: "01:30", text: "01:30" },
  { value: "02:00", text: "02:00" },
  { value: "02:30", text: "02:30" },
  { value: "03:00", text: "03:00" },
  { value: "03:30", text: "03:30" },
  { value: "04:00", text: "04:00" },
  { value: "04:30", text: "04:30" },
  { value: "05:00", text: "05:00" },
  { value: "05:30", text: "05:30" },
  { value: "06:00", text: "06:00" },
  { value: "06:30", text: "06:30" },
  { value: "07:00", text: "07:00" },
  { value: "07:30", text: "07:30" },
  { value: "08:00", text: "08:00" },
  { value: "08:30", text: "08:30" },
  { value: "09:00", text: "09:00" },
  { value: "09:30", text: "09:30" },
  { value: "10:00", text: "10:00" },
  { value: "10:30", text: "10:30" },
  { value: "11:00", text: "11:00" },
  { value: "11:30", text: "11:30" },
  { value: "12:00", text: "12:00" },
  { value: "12:30", text: "12:30" },
  { value: "13:00", text: "13:00" },
  { value: "13:30", text: "13:30" },
  { value: "14:00", text: "14:00" },
  { value: "14:30", text: "14:30" },
  { value: "15:00", text: "15:00" },
  { value: "15:30", text: "15:30" },
  { value: "16:00", text: "16:00" },
  { value: "16:30", text: "16:30" },
  { value: "17:00", text: "17:00" },
  { value: "17:30", text: "17:30" },
  { value: "18:00", text: "18:00" },
  { value: "18:30", text: "18:30" },
  { value: "19:00", text: "19:00" },
  { value: "19:30", text: "19:30" },
  { value: "20:00", text: "20:00" },
  { value: "20:30", text: "20:30" },
  { value: "21:00", text: "21:00" },
  { value: "21:30", text: "21:30" },
  { value: "22:00", text: "22:00" },
  { value: "22:30", text: "22:30" },
  { value: "23:00", text: "23:00" },
  { value: "23:30", text: "23:30" },
  { value: "24:00", text: "24:00" },
];

const hoursListOnly = [
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "24:00",
];

const userTypesList = [
  { value: "admin", text: "Admin" },
  { value: "superadmin", text: "Super admin" },
  { text: "Manager", value: "manager" },
  { text: "ABC", value: "abc" },
  { text: "ManagerABC", value: "manager_abc" },
  { text: "Control", value: "control" },
  { text: "Recepción", value: "reception" },
  { text: "Caja", value: "cash_register" },
  { text: "Externos", value: "external" },
  { text: "Quirófano", value: "operating_room" },
  { text: "Médico", value: "medic" },
  { text: "Enfermería", value: "nursing" },
  { text: "Nutrición", value: "nutrition" },
  { text: "Psicólogo", value: "psychology" },
  { text: "Laboratorio", value: "laboratory" },
  { text: "ManagerCacit", value: "manager_cacit" },
  { text: "Cacit", value: "cacit" },
];

const externalTypesList = [
  { text: "Médico", value: "medic" },
  { text: "Anestesiólogo", value: "anesthesiologist" },
  { text: "Ginecólogo Gestacional", value: "gestational_gynecologist" },
];

module.exports = {
  minutesList,
  hoursList,
  hoursListSettings,
  hoursListOnly,
  userTypesList,
  agendaTypes,
  agendaFields,
  externalTypesList,
};
