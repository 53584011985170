<template>
  <div class="appointmentModal">
    <div class="my-5 text-center titles">{{ title }}</div>
    <div class="appointmentModal___content mb-3" v-if="appointmentInfo">
      <!-- Static Info -->
      <div class="mb-3">
        <font-awesome-icon
          class="tableIcon me-4 pinkIcon"
          :icon="['far', 'user']" />
        <span class="grayText">{{ appointmentInfo.patient_name }}</span>
      </div>
      <div class="mb-3">
        <font-awesome-icon class="tableIcon me-4" :icon="['far', 'id-badge']" />
        <span class="grayText">
          Filiación: {{ appointmentInfo.patient_filiation_num }}
        </span>
      </div>
      <!-- Static Info / -->

      <!-- Date Input -->
      <div class="mb-3">
        <font-awesome-icon
          class="tableIcon me-4 mt-2"
          :icon="['far', 'calendar']" />
        <div class="d-inline-block mb-0">
          <date-picker
            v-if="isRescheduleAction"
            :format="'DD/MM/YYYY'"
            v-model="appointmentInfo.date"
            class="mb-0-datepicker"
            :input-class="'fs-5 form-control inputsGeneralClass w-100 mb-0'"
            placeholder="Seleccione una fecha" />
          <p class="mt-1 mb-0 grayText" v-else>
            {{ `${this.$moment(appointmentInfo.date).format("DD/MM/YYYY")}` }}
          </p>
        </div>
      </div>
      <!-- Date Input / -->

      <!-- Clinic Input -->
      <div
        v-if="
          permissionSelectClinics.includes(getUserType) || !isRescheduleAction
        "
        class="mb-3">
        <font-awesome-icon
          class="tableIcon me-3 mt-2"
          :icon="['fa', 'clinic-medical']" />
        <div class="d-inline-block mb-0">
          <select
            v-if="isRescheduleAction"
            class="fs-5 form-select grayText inputsGeneralClass w-100"
            v-model="appointmentInfo.clinic_id">
            <option
              v-for="(clinic, index) in getClinicList"
              :key="clinic + index"
              class="fs-5"
              :value="clinic.id"
              selected>
              {{ clinic.name }}
            </option>
          </select>
          <p class="ms-1 mt-1 mb-0 grayText" v-else>
            {{ clinicNameById(appointmentInfo.clinic_id) }}
          </p>
        </div>
      </div>
      <!-- Clinic Input / -->

      <!-- Hour Input -->
      <div class="mb-3">
        <font-awesome-icon
          class="tableIcon mt-2 me-4"
          :icon="['far', 'clock']" />
        <div class="d-inline-block mb-0">
          <select
            v-if="isRescheduleAction"
            class="fs-5 form-select grayText inputsGeneralClass w-100"
            v-model="appointmentInfo.time">
            <option
              v-for="(hourOnly, index) in hoursList"
              :key="hourOnly + index"
              class="fs-5"
              :value="hourOnly"
              selected>
              {{ hourOnly }}
            </option>
          </select>
          <p class="mt-1 mb-0 grayText" v-else>{{ appointmentInfo.time }}</p>
        </div>
      </div>
      <!-- Hour Input / -->
      <template v-if="isRescheduleAction">
        <hr />
        <h4 class="titles">Disponibilidad de médicos</h4>
        <p class="text-info">
          *Se muestran los médicos disponibles en base a
          <strong>fecha, sucursal y hora</strong>
          seleccionados.
        </p>
        <p>
          Médico seleccionado antes de editar:
          <span class="text-primary" v-if="medicBeforeEdit">
            {{ medicBeforeEdit.name }}
          </span>
        </p>
      </template>

      <!-- Medic Input -->
      <div class="mb-3" v-if="!isRescheduleAction">
        <font-awesome-icon
          class="tableIcon mt-2 me-4"
          :icon="['fa', 'user-md']" />
        <div class="d-inline-block mb-0">
          <p class="ms-1 mt-1 mb-0 grayText">
            {{ appointmentInfo.medic_name }}
          </p>
        </div>
      </div>
      <div
        v-if="permissionSelectMedics.includes(getUserType)"
        :class="{ 'mb-0': !isRescheduleAction }">
        <font-awesome-icon
          class="tableIcon me-3 mt-2"
          :icon="['fa', 'file-pen']" />
        <div class="d-inline-block mb-0">
          <select
            v-if="isRescheduleAction && medicList.length > 0"
            class="col-11 fs-5 form-select grayText inputsGeneralClass w-100"
            v-model="appointmentInfo.medic_id">
            <option
              v-for="(medic, medicIndex) in medicList"
              :key="medic + medicIndex"
              class="fs-5"
              :value="medic.id"
              selected>
              {{ medic.name }}
            </option>
          </select>
          <p
            v-else-if="!isOtherMedicAvailableBeforeEditData"
            class="text-secondary">
            <span v-if="medicBeforeEdit">
              Disponible: {{ medicBeforeEdit.name }}
            </span>
            <br />
            <strong>
              No hay otros médicos disponibles en esta sucursal con este
              horario.
            </strong>
          </p>
          <p
            v-else-if="isNotMedicAvailableWithOtherEditData"
            class="text-primary">
            <strong>
              No hay médicos disponibles en esta sucursal con este horario.
            </strong>
          </p>
          <p class="ms-1 mt-1 mb-0 grayText" v-else-if="medicBeforeEdit">
            {{ appointmentInfo.user_complete_name }}
          </p>
          <p class="text-danger" v-if="$v.appointmentInfo.medic_id.$error">
            Seleccionar un médico disponible es requerido.
          </p>
        </div>
      </div>
      <!-- Medic Input / -->
    </div>
    <!-- Services List -->
    <div class="appointmentModal__services" v-if="showServiceRule">
      <b-table
        :items="appointmentInfo.services"
        :fields="servicesFields"
        :table-class="'patientsHeadClass'"
        striped
        borderless />
    </div>
    <!-- Services List /-->

    <!-- Footer Actions -->
    <div class="appointmentModal__footer">
      <b-button class="col-5 w-100 btn btn-secondary" @click="closeModal">
        Cancelar
      </b-button>
      <b-button
        class="col-5 w-100 btn btn-primary"
        variant="primary"
        :disabled="isDisabled"
        @click="actionAppointment">
        {{ buttonText }}
      </b-button>
    </div>
    <!-- Footer Actions / -->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { hoursListOnly } from "@/constants/settings";
import { clinicsNames, servicesFields } from "@/constants/appointments";
import {
  permissionSelectClinics,
  permissionSelectMedics,
} from "@/constants/patient";

import { mapGetters, mapActions } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import clinicAndMedic from "@/mixin/clinicAndMedic.mixin";

export default {
  name: "Appointment",
  mixins: [clinicAndMedic],
  components: {
    DatePicker,
  },
  data() {
    return {
      servicesFields,
      clinicsNames,
      hoursList: hoursListOnly,
      permissionSelectMedics,
      permissionSelectClinics,
      medicBeforeEdit: {},
      appointmentInfo: null,
      timeBeforeEdit: "",
      dateBeforeEdit: "",
      clinicBeforeEdit: "",
    };
  },
  validations() {
    const validations = {
      appointmentInfo: {
        date: {
          required,
        },
        time: {
          required,
        },
        clinic_id: {
          required,
        },
        medic_id: {
          required,
        },
      },
    };
    if (Object.keys(this.appointmentInfo).length === 0) {
      return {};
    }
    return validations;
  },
  watch: {
    appointmentInfo() {
      this.$watch("appointmentInfo.date", this.fillMedicList, { deep: true });
      this.$watch("appointmentInfo.time", this.fillMedicList, { deep: true });
      this.$watch("appointmentInfo.clinic_id", this.fillMedicList, {
        deep: true,
      });
    },
  },
  computed: {
    ...mapGetters([
      "getAppointmentTypeAction",
      "getPatientSelected",
      "getSelectedPatientID",
      "getModal",
      "getUserProfile",
      "getUserType",
      "getClinicList",
      "getClinic",
      "getMedic",
      "getSelectedPatientNextAppointment",
    ]),
    isDisabled() {
      return (
        this.getUserType === "cacit" &&
        this.getUserProfile.id !== this.appointmentInfo?.user_id
      );
    },
    showServiceRule() {
      return (
        !this.isRescheduleAction && this.appointmentInfo?.services.length > 0
      );
    },
    isSameFilterBeforeEdit() {
      return (
        this.clinicBeforeEdit === this.appointmentInfo.clinic_id &&
        this.dateBeforeEdit.getTime() === this.appointmentInfo.date.getTime() &&
        this.timeBeforeEdit === this.appointmentInfo.time
      );
    },
    isSomeFilterDiffBeforeEdit() {
      return (
        this.clinicBeforeEdit !== this.appointmentInfo.clinic_id &&
        this.dateBeforeEdit.getTime() !== this.appointmentInfo.date.getTime() &&
        this.timeBeforeEdit !== this.appointmentInfo.time
      );
    },
    isOtherMedicAvailableBeforeEditData() {
      return !(
        this.isRescheduleAction &&
        ((this.medicList.length === 0 && this.isSameFilterBeforeEdit) ||
          (this.medicList.length === 1 &&
            this.medicList.some(
              (medic) => medic.id === this.medicBeforeEdit.id
            )))
      );
    },
    isNotMedicAvailableWithOtherEditData() {
      return (
        this.isRescheduleAction &&
        this.medicList.length === 0 &&
        this.isSomeFilterDiffBeforeEdit
      );
    },
    isRescheduleAction() {
      return this.getAppointmentTypeAction === "reschedule";
    },
    title() {
      return this.isRescheduleAction ? "Reagendar cita" : "Confirmar cita";
    },
    buttonText() {
      return this.isRescheduleAction ? "Reagendar" : "Confirmar";
    },
    hasPermitionShowServices() {
      const hasPermition =
        this.getUserType === "superadmin" ||
        this.getUserType === "admin" ||
        this.getUserType === "control" ||
        this.getUserType === "reception";
      return hasPermition;
    },
  },
  methods: {
    ...mapActions([
      "updateClinic",
      "updateMedic",
      "updateMedicList",
      "updateAlerts",
    ]),
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error;
    },
    getAppointmentInfo() {
      const id = this.getSelectedPatientNextAppointment;
      this.$api.appointments.getInfo(id).then((response) => {
        this.timeBeforeEdit = response.data.time.slice(0, -3);
        this.dateBeforeEdit = this.$moment(response.data.date).toDate();
        this.clinicBeforeEdit = response.data.clinic_id;
        this.appointmentInfo = response.data;
        this.appointmentInfo.time = this.appointmentInfo.time.slice(0, -3);
        this.appointmentInfo.date = this.$moment(
          this.appointmentInfo.date
        ).toDate();
        this.medicBeforeEdit = {
          id: response.data.medic_id,
          name: response.data.medic_name,
        };
        if (this.isRescheduleAction) {
          this.updateMedicListOnMounted(this.appointmentInfo);
        }
      });
    },
    clinicNameById(id) {
      return this.clinicsNames?.find((clinic) => clinic.id === id)?.name;
    },
    generatePayload(info = null, type = null) {
      const payload = {};
      if (info) {
        payload.clinic_id = info.clinic_id;
        payload.date = info.date;
        payload.time = info.time;
      } else {
        payload.clinic_id = this.appointmentInfo.clinic_id;
        payload.date = this.appointmentInfo.date;
        payload.time = this.appointmentInfo.time;
        if (type === "reschedule") {
          payload.medic_id = this.appointmentInfo.medic_id;
          payload.patient_id = this.appointmentInfo.patient_id;
          payload.appointment_type = "reschedule";
        }
      }
      return payload;
    },
    getFunctionByType() {
      const type = this.getAppointmentTypeAction;
      return `action${type.capitalize()}`;
    },
    actionAppointment() {
      this[this.getFunctionByType()]();
    },
    actionPending() {
      this.$api.appointments
        .confirmation(this.getSelectedPatientNextAppointment)
        .then(() => {
          this.$emit("reloadPatientList");
          let includesAsesorString = false;
          this.appointmentInfo.services.forEach((service) => {
            if (service.name.toLowerCase().includes("asesor")) {
              includesAsesorString = true;
            }
          });
          if (!includesAsesorString || this.hasPermitionShowServices) {
            this.$emit("showServices", this.appointmentInfo.patient_id);
          }
          this.closeModal();
        });
    },
    actionReschedule() {
      const payload = this.generatePayload(null, "reschedule");
      if (this.isFormDataValid()) {
        this.$api.appointments
          .update(this.getSelectedPatientNextAppointment, payload)
          .then(() => {
            this.$emit("reloadPatientList");
            this.closeModal();
          });
      }
    },
    closeModal() {
      this.getModal("generalFiliationModal").hide();
    },
  },
};
</script>
